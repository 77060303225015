import { ExtendedImage as PrismaImage } from "@/lib/image"
import { useState } from "react"
import { toast } from "sonner"
import { Button } from "./ui/button"
import UploadImageForm from "./upload-image-form"

export default function AfterTravauxImageForm({ projectId, onSubmit }: { projectId: string, onSubmit: () => void }) {
  const [images, setImages] = useState<PrismaImage[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const createImages = async (images: PrismaImage[], projectId: string) => {
    setIsLoading(true)
    try {
      if (images.length === 0) {
        toast.error('Aucune image trouvée')
        return
      }

      const data = images.map((image) => ({
        url: image.url,
        title: image.title || '',
        description: image.description || '',
        project: { connect: { id: projectId } },
        tags: { connect: [{ name: 'Après' }] }
      }))

      const response = await fetch('/api/image', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })

      if (response.ok) {
        onSubmit()
      } else {
        toast.error('Erreur lors de l\'enregistrement des images')
      }
    } catch (error) {
      toast.error('Erreur lors de l\'enregistrement des images')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await createImages(images, projectId)
  }


  return (
    <form onSubmit={handleSubmit}>
      <UploadImageForm initialImages={images} allTags={[]} onImagesChange={setImages} />
      {images.length > 0 && <Button type="submit">Enregistrer</Button>}
    </form>
  )
}
