import { useState, useRef } from 'react'
import { PutBlobResult } from '@vercel/blob'
import { Button } from '@/components/ui/button'
import { toast } from 'sonner'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'

export const UploadOneImage = ({ onImageUploaded, accept, className }: { onImageUploaded: (imageUrl: string) => void, accept: string, className?: string }) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [fileUploaded, setFileUploaded] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)

  const handleUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setUploading(true)
    event.stopPropagation()
    event.preventDefault()

    if (!inputFileRef.current?.files) {
      toast.error("No file selected")
      setUploading(false)
      return
    }

    const file = inputFileRef.current.files[0]
    const formData = new FormData()
    formData.append('files', file)
    const response = await fetch(
      `/api/image/upload`,
      {
        method: 'POST',
        body: formData,
      },
    )

    if (!response.ok) {
      toast.error('Erreur lors du téléchargement')
      setUploading(false)
      return
    }

    const newBlob = (await response.json()) as PutBlobResult[]
    onImageUploaded(newBlob[0].url)
    setUploading(false)
    setFileUploaded(false)
  }

  return (
    <div className={cn('flex items-center gap-4 w-1/2 mx-auto', className)}>
      <Input type='file' className='w-full cursor-pointer' ref={inputFileRef} onChange={(e) => setFileUploaded(e.target.value.length > 0)} accept={accept} />
      {fileUploaded && (
        <Button onClick={handleUpload} className='full flex gap-2' loading={uploading}>
          Upload
        </Button>
      )}
    </div>
  )
}

