'use client'
import { Company } from '@prisma/client'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { LoadingSpinner } from './loading-spinner'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "./ui/select"

const CompanySelect = ({ targetId, targetType, companyId, onUpdate, className }: { targetId: string, targetType: 'project' | 'agency', companyId?: string, onUpdate?: (companyId: string) => void, className?: string }) => {
  const [allCompanies, setAllCompanies] = useState<Company[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(companyId || undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const fetchCompanies = async () => {
      const response = await fetch(`${process.env.NEXT_PUBLIC_URL}/api/companies`, { cache: 'no-store' })
      if (!response.ok) {
        console.error('Failed to fetch companies')
        return
      }
      const companies = await response.json()

      setAllCompanies(companies)
    }

    fetchCompanies()
    setLoading(false)
  }, [])

  const handleSelect = (value: string) => {
    setSelectedCompanyId(value)
    handleUpdate(value)
  }

  const handleUpdate = async (value: string) => {
    const body = value === 'none' 
      ? { [targetType === 'agency' ? 'assignedCompany' : 'company']: { disconnect: true } }
      : { [targetType === 'agency' ? 'assignedCompany' : 'company']: { connect: { id: value } } }
    
    const response = await fetch(`${process.env.NEXT_PUBLIC_URL}/api/${targetType}/${targetId}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })

    if (!response.ok) {
      toast.error('Failed to update project')
      console.error(response)
      return
    }

    const message = value === 'none' 
      ? 'Société déconnectée du projet'
      : `Société ${allCompanies.find((company) => company.id === value)?.name} assignée au projet`
    
    toast.success(message)
    onUpdate?.(value)
  }

  return (
    <Select onValueChange={(value) => { handleSelect(value) }} value={selectedCompanyId}>
      <SelectTrigger className={`w-full ${className}`}>
        <SelectValue placeholder="Sélectionner une entreprise" />
      </SelectTrigger>
      <SelectContent>
        {loading ? <LoadingSpinner /> :
          <SelectGroup>
            <SelectLabel>Sociétés</SelectLabel>
            {allCompanies.map((company) => (
              <SelectItem key={company.id} value={company.id}>
                {company.name}
              </SelectItem>
            ))}
            <SelectItem value="none">Aucune</SelectItem>
          </SelectGroup>
        }
      </SelectContent>
    </Select>
  )
}

export default CompanySelect