'use client'

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { UploadOneImage } from "@/components/upload-one-image"
import { useCalendly } from "@/hooks/useCalendly"
import { Company, User } from "@prisma/client"
import { Check } from "lucide-react"
import { useState } from "react"
import { toast } from "sonner"
import { LoadingSpinner } from "./loading-spinner"

export default function InformationCompanyForm({ company, user, onSubmit }: { company: Company, user: User, onSubmit: () => void }) {
  const [kbis, setKbis] = useState<string | null>(company.kbis || null)
  const [attestation, setAttestation] = useState<string | null>(company.attestation || null)
  const [loading, setLoading] = useState(false)
  const { getMeAndSubscribeToWebhook } = useCalendly()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    event.preventDefault()
    const form = event.currentTarget
    if (!kbis || !attestation) {
      toast.error("Veuillez uploader les documents requis")
      setLoading(false)
      return
    }

    let data = {
      siret: (form.elements.namedItem('siret') as HTMLInputElement).value,
      adresse: (form.elements.namedItem('adresse') as HTMLInputElement).value,
      tva: (form.elements.namedItem('tva') as HTMLInputElement).value,
      calendlyGestionUrl: (form.elements.namedItem('calendlyGestionUrl') as HTMLInputElement).value,
      calendlyTransactionUrl: (form.elements.namedItem('calendlyTransactionUrl') as HTMLInputElement).value,
      calendlyRDVUrl: (form.elements.namedItem('calendlyRDVUrl') as HTMLInputElement).value,
      calendlyApiKey: (form.elements.namedItem('calendlyApiKey') as HTMLInputElement).value,
      kbis: kbis,
      attestation: attestation,
    }

    if (!checkSiret(data.siret)) {
      toast.error("Le numéro SIRET doit contenir 14 chiffres")
      setLoading(false)
      return
    }
    if (!checkTva(data.tva)) {
      toast.error("Le numéro de TVA doit être au format FR12345678900")
      setLoading(false)
      return
    }

    try {
      const response = await fetch(`/api/company/${company.id}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
      })
      if (!response.ok) throw new Error('Submission failed')
      toast.success("Formulaire soumis")
      setLoading(false)
      await fetch(`/api/user/${user.id}`)
      if ((data.calendlyApiKey !== company.calendlyApiKey) || !company.calendlyApiConnected) {
        try {
          await getMeAndSubscribeToWebhook({ apiKey: data.calendlyApiKey })
          toast.success("Connexion à Calendly réussie")
          const response = await fetch(`/api/company/${company.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ calendlyApiConnected: true }),
          })
          if (!response.ok) throw new Error('Erreur lors de la mise à jour de la connexion à Calendly avec le projet')
        } catch (subscribeError) {
          if (subscribeError instanceof Error) {
            toast.error(`Erreur lors de la connexion à Calendly: ${subscribeError.message}`)
          } else {
            toast.error('Erreur lors de la connexion à Calendly')
          }
        }
      }
      onSubmit()
    } catch (error) {
      if (error instanceof Error) {
        console.error('❌', error)
        toast.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const checkSiret = (siret: string) => {
    return /^[0-9]{14}$/.test(siret)
  }

  const checkTva = (tva: string) => {
    return /^FR[0-9]{11}$/.test(tva)
  }

  return (
    <Card className="w-full max-w-3xl">
      <CardHeader>
        <CardTitle className="text-lg font-bold">Information sur votre entreprise</CardTitle>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="siret">Numéro SIRET</Label>
            <Input type="number" id="siret" placeholder="123 456 789 00012" required defaultValue={company.siret || ''} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="adresse">Adresse de l&apos;entreprise</Label>
            <Input type="text" id="adresse" placeholder="123 rue de la Paix, 75000 Paris" required defaultValue={company.adresse || ''} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="tva">Numéro de TVA</Label>
            <Input type="text" id="tva" placeholder="FR12345678900" required defaultValue={company.tva || ''} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="calendlyGestionUrl">URL Calendly Gestion</Label>
            <Input type="text" id="calendlyGestionUrl" placeholder="https://calendly.com/..." defaultValue={company.calendlyGestionUrl || ''} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="calendlyTransactionUrl">URL Calendly Transaction</Label>
            <Input type="text" id="calendlyTransactionUrl" placeholder="https://calendly.com/..." defaultValue={company.calendlyTransactionUrl || ''} />
          </div>
          <div className="space-y-2">
            <Label htmlFor="calendlyRDVUrl">URL Calendly RDV</Label>
            <Input type="text" id="calendlyRDVUrl" placeholder="https://calendly.com/..." defaultValue={company.calendlyRDVUrl || ''} />
          </div> 
          <div className="space-y-2">
            <Label htmlFor="kbis">Extrait K-bis</Label>
            <div className="flex items-center gap-2">
              <UploadOneImage className='w-full' onImageUploaded={(imageUrl: string) => setKbis(imageUrl)} accept='application/pdf' />
              {kbis && <Check />}
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="attestation">Attestation d&apos;assurance</Label>
            <div className="flex items-center gap-2">
              <UploadOneImage className='w-full' onImageUploaded={(imageUrl: string) => setAttestation(imageUrl)} accept='application/pdf' />
              {attestation && <Check />}
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="calendlyApiKey">Clé API Calendly</Label>
            <Input type="text" id="calendlyApiKey" placeholder="Entrez votre clé API" defaultValue={company.calendlyApiKey || ''} />
          </div>
        </CardContent>
        <CardFooter>
          <Button type="submit" className="w-full" loading={loading}>Soumettre</Button>
        </CardFooter>
      </form>
    </Card>
  )
}