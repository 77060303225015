"use client"
import React, { useState } from 'react'
import { signOut } from "next-auth/react"
import { Button } from "@/components/ui/button"
import { LogOut } from 'lucide-react'


const SignoutButton = ({ className }: { className?: string }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSignout = async () => {
    setIsLoading(true)
    try {
      await signOut()
    } catch (error) {
      console.error("🔴 Error signing out:", error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Button onClick={handleSignout} variant="destructive" className={`flex items-center gap-2 ${className}`} loading={isLoading}>
      <LogOut className="w-4 h-4" /> <p className="font-bold">Me déconnecter</p>
    </Button>
  )
}

export default SignoutButton