'use client'
import { Button } from "@/components/ui/button"
import { ExtendedProject as Project } from "@/lib/project"
import { useEffect, useState } from "react"
import Selector from "./selector"
import { toast } from "sonner"
import { sendInvoiceEstimateDateEmail } from "@/server/email"

export default function InvoiceEstimateDateForm({ project, onSubmit }: { project: Project, onSubmit: (project: Project) => void }) {
  const [timeEstimate, setTimeEstimate] = useState<number | undefined>()
  const [invoiceEstimateDate, setInvoiceEstimateDate] = useState<Date | undefined>(project.invoiceEstimateDate || undefined)
  const [isLoading, setIsLoading] = useState(false)

  const timeEstimateOptions = [
    { value: '1', label: '1 jour' },
    { value: '2', label: '2 jours' },
    { value: '3', label: '3 jours' },
    { value: '7', label: '7 jours' },
    { value: '15', label: '15 jours' },
    { value: '30', label: '30 jours' },
  ]

  useEffect(() => {
    if (timeEstimate) {
      const date = new Date()
      date.setDate(date.getDate() + timeEstimate)
      setInvoiceEstimateDate(date)
    }
  }, [timeEstimate])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const updatedProject = await fetch('/api/project/' + project.id, {
        method: 'PATCH',
        body: JSON.stringify({ invoiceEstimateDate: invoiceEstimateDate })
      }).then(res => res.json())
      if (updatedProject.invoiceEstimateDate) {
        onSubmit(updatedProject)
        sendInvoiceEstimateDateEmail(updatedProject)
      } else {
        throw new Error('Une erreur est survenue lors de la mise à jour du projet')
      }
    } catch (error) {
      console.error(error)
      toast.error('Une erreur est survenue lors de la mise à jour du projet')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <Selector
        options={timeEstimateOptions}
        value={timeEstimate?.toString() || ''}
        onValueChange={(value: string | null) => {
          setTimeEstimate(value ? parseInt(value) : undefined)
        }}
        placeholder="Délai de réalisation du devis"
        className="w-fit"
      />
      {invoiceEstimateDate && <p>Le devis devrait donc être prêt avant le {invoiceEstimateDate?.toLocaleDateString('fr-FR')}</p>}
      {invoiceEstimateDate && <Button type="submit" loading={isLoading}>Valider</Button>}
    </form>
  )
}