"use client"

import { ExtendedProject as Project } from "@/lib/project"
import { ExtendedUser as User } from "@/lib/user"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { MenuIcon } from "lucide-react"
import NavBar from "./nav-bar"
import { useState } from "react"

interface MobileNavProps {
  user?: User
  projects: Project[]
}

export default function MobileNav({ user, projects }: MobileNavProps) {
  const [open, setOpen] = useState(false)

  return (
    <div className="sm:hidden z-20 w-full fixed backdrop-blur-md">
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger className="h-10 w-full" asChild>
          <Button className="w-full rounded-none">
            <MenuIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={0}>
          <NavBar className="w-screen" user={user} projects={projects} onNavigate={() => setOpen(false)} />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
} 