'use client'

import AfterTravauxImageForm from '@/components/after-travaux-image-form'
import CompanyEditForm from '@/components/company-edit-form'
import CompanySelect from '@/components/company-select'
import DateTravauxForm from '@/components/date-travaux-form'
import InvoiceEstimateDateForm from '@/components/invoice-estimate-date-form'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { ExtendedProject as Project } from '@/lib/project'
import { ExtendedUser as User } from '@/lib/user'
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'

export default function useBlockingPopup({ user, projects }: { user: User, projects: Project[] }) {
  const [popupContent, setPopupContent] = useState<React.ReactNode | null>(null)
  const [projectsWithoutCompany, setProjectsWithoutCompany] = useState<Project[]>([])
  const [projectNeedingInvoiceEstimateDate, setProjectNeedingInvoiceEstimateDate] = useState<Project | null>(null)
  const [projectNeedingDateTravaux, setProjectNeedingDateTravaux] = useState<Project | null>(null)
  const [projectNeedingPhotos, setProjectNeedingPhotos] = useState<Project | null>(null)
  const router = useRouter()

  // societe non validée
  useEffect(() => {
    if (!user) return
    if (user.directedCompany) {
      if (!user.directedCompany.validated) {
        setPopupContent(
          <CompanyEditForm
            company={user.directedCompany}
            user={user}
            onSubmit={() => setPopupContent(null)}
          />
        )
      }
    }

    if (user.role === 'SUPER_ADMIN') {
      setProjectsWithoutCompany(projects.filter(project => project.companyId === null))
    }

    if (user.directedCompany) {
      setProjectNeedingInvoiceEstimateDate(projects.find(project => !project.invoiceEstimateDate && project.status?.name === 'Devis en préparation') || null)
      setProjectNeedingDateTravaux(projects.find(project => (!project.dateDebutTravaux || !project.dateFinTravaux) && project.status?.name === 'Devis signé') || null)
      const projectsWithNoAfterImages = projects.filter(project => project.images?.length === 0 || !project.images?.some(image => image.tags.some(tag => tag.type === 'TEMPOREL' && tag.name === 'Après')))
      setProjectNeedingPhotos(projectsWithNoAfterImages.find(project => project.status?.name === 'Travaux terminés') || null)
    }
  }, [user, projects])

  // photos après travaux
  useEffect(() => {
    if (projectNeedingPhotos) {
      setPopupContent(
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <h2>Projet au {projectNeedingPhotos.adresseChantier}</h2>
            <h2>Veuillez uploader des photos des travaux effectués</h2>
          </div>
          <AfterTravauxImageForm projectId={projectNeedingPhotos.id} onSubmit={() => {
            setProjectNeedingPhotos(null)
            setPopupContent(null)
            toast.success('Photos enregistrées')
          }}
          />
        </div>
      )
    }
  }, [projectNeedingPhotos])

  //estimation de date devis
  useEffect(() => {
    if (projectNeedingInvoiceEstimateDate) {
      setPopupContent(
        <div>
          <h2>Dans quel délai pensez-vous pouvoir préparer le devis pour le projet au {projectNeedingInvoiceEstimateDate.adresseChantier} ?</h2>
          <InvoiceEstimateDateForm project={projectNeedingInvoiceEstimateDate} onSubmit={(updatedProject) => {
            if (updatedProject.invoiceEstimateDate) {
              console.log('updatedProject', updatedProject)
              setProjectNeedingInvoiceEstimateDate(null)
              toast.success('Date enregistrée')
              setPopupContent(null)
            }
          }}
          />
        </div>
      )
    }
  }, [projectNeedingInvoiceEstimateDate])

  //date de début et fin travaux
  useEffect(() => {
    if (projectNeedingDateTravaux) {
      setPopupContent(
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <h2>Projet au {projectNeedingDateTravaux.adresseChantier}</h2>
            <h2>Veuillez indiquer la date de début et de fin des travaux</h2>
          </div>
          <DateTravauxForm project={projectNeedingDateTravaux} onSubmit={(updatedProject) => {
            if (updatedProject.dateDebutTravaux && updatedProject.dateFinTravaux) {
              setProjectNeedingDateTravaux(null)
              toast.success('Dates enregistrées')
              setPopupContent(null)
            }
          }} />
        </div>
      )
    }
  }, [projectNeedingDateTravaux])

  useEffect(() => {
    if (projectsWithoutCompany.length > 0) {
      setPopupContent(
        <div>
          <h2>Ces projets n&apos;ont pas de société attribuée.</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Adresse</TableHead>
                <TableHead>Société</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {projectsWithoutCompany.map(project => (
                <TableRow key={project.id}>
                  <TableCell>{project.adresseChantier}</TableCell>
                  <TableCell>
                    <CompanySelect
                      targetId={project.id}
                      targetType="project"
                      companyId={project.companyId || undefined}
                      onUpdate={(companyId) => {
                        try {
                          fetch(`${process.env.NEXT_PUBLIC_URL}/api/project/${project.id}`, {
                            method: 'PATCH',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ status: { connect: { name: 'Prise de contact' } } })
                          })
                          
                          setProjectsWithoutCompany(prev => prev.filter(p => p.id !== project.id))
                          router.refresh()
                        } catch (error) {
                          toast.error('Erreur lors de la mise à jour de la société')
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )
    }

  }, [projectsWithoutCompany])

  return popupContent
}
