'use client'

import { Project } from "@prisma/client"
import DatePicker from "./date-picker"
import { useState } from "react"
import { Button } from "./ui/button";
import { sendDateTravauxEmail } from "@/server/email"
import { toast } from "sonner"

interface DateTravauxFormProps {
  project: Project;
  onSubmit: (updatedProject: Project) => void;
}

export default function DateTravauxForm({ project, onSubmit }: DateTravauxFormProps) {
  const [dateDebutTravaux, setDateDebutTravaux] = useState<Date | undefined>(undefined)
  const [dateFinTravaux, setDateFinTravaux] = useState<Date | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const updatedProject = await fetch('/api/project/' + project.id, {
        method: 'PATCH',
        body: JSON.stringify({ dateDebutTravaux: dateDebutTravaux, dateFinTravaux: dateFinTravaux })
      }).then(res => res.json())
      if (updatedProject.dateDebutTravaux && updatedProject.dateFinTravaux) {
        onSubmit(updatedProject)
        sendDateTravauxEmail(updatedProject)
      } else {
        throw new Error('Une erreur est survenue lors de la mise à jour du projet')
      }
    } catch (error) {
      console.error(error)
      toast.error('Une erreur est survenue lors de la mise à jour du projet')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        {dateDebutTravaux && <p>Date de début des travaux</p>}
        <DatePicker placeholder="Date de début des travaux" value={dateDebutTravaux} onValueChange={setDateDebutTravaux} />
      </div>
      <div className="flex flex-col gap-1">
        {dateFinTravaux && <p>Date de fin des travaux</p>}
        <DatePicker placeholder="Date de fin des travaux" value={dateFinTravaux} onValueChange={setDateFinTravaux} />
      </div>
      {dateDebutTravaux && dateFinTravaux &&
        <Button type="submit" loading={isLoading}>Enregistrer</Button>
      }
    </form>
  );
}