import { useState } from "react"

export const useCalendly = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [data, setData] = useState(null)

  const getMe = async ({ apiKey }: { apiKey: string }) => {
    try {
      const res = await fetch('https://api.calendly.com/users/me', {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      })
      const data = await res.json()
      if (!res.ok) {
        if (data.message === 'The access token is invalid') {
          throw new Error('La clé API est invalide')
        }
        throw new Error(data.message || 'Echec dans la récupération des informations de l\'utilisateur')
      }

      const organization = data.resource.current_organization
      const user = data.resource.uri

      return { user, organization }
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      } else {
        setError(new Error('An unknown error occurred'))
      }
      throw error
    }
  }
  const subscribeToWebhook = async ({ user, organization, apiKey }: { user: string, organization: string, apiKey: string }) => {
    setIsLoading(true)
    try {
      const data = {
        "url": "https://moneodomus.com/api/calendly-webhook",
        "events": [
          "invitee.created",
          "invitee.canceled",
          "invitee_no_show.created"
        ],
        "user": user,
        "organization": organization,
        "scope": "user"
      }
      const res = await fetch('https://api.calendly.com/webhook_subscriptions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      if (!res.ok) {
        const json = await res.json()
        const errorMessage = json.message
        if (errorMessage.includes('Please upgrade your Calendly account')) {
          throw new Error('Veuillez souscrire un compte Calendly Standard')
        } else {
          throw new Error(errorMessage || 'Echec dans la souscription au webhook')
        }
      }
      const subscribed = await res.json()
      console.log("🌈 subscribed", subscribed)
      return subscribed
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      } else {
        setError(new Error('An unknown error occurred'))
      }
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const getMeAndSubscribeToWebhook = async ({ apiKey }: { apiKey: string }) => {
    setIsLoading(true)
    try {
      const { user, organization } = await getMe({ apiKey })
      const subscribed = await subscribeToWebhook({ user, organization, apiKey })
      console.log("🌈 subscribed", subscribed)
      return subscribed
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      } else {
        setError(new Error('An unknown error occurred'))
      }
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, error, data, getMeAndSubscribeToWebhook }
}