import React from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from './ui/select'
import { Button } from './ui/button'
import { XIcon } from 'lucide-react'

interface SelectorOption {
  value: string
  label?: string
}

interface SelectorGroup {
  groupLabel?: string
  options: SelectorOption[]
}

interface SelectorProps {
  value: string | undefined
  options: SelectorOption[] | SelectorGroup[]
  placeholder?: string
  onValueChange: (value: string) => void
  renderOption?: (option: SelectorOption) => React.ReactNode
  className?: string
  required?: boolean
}

function isGroupOption(option: any): option is { groupLabel?: string, options: SelectorOption[] } {
  return 'options' in option
}

const Selector = ({ value, options, placeholder, onValueChange, renderOption, className, required }: SelectorProps) => {
  return (
    <div className={`flex bg-secondary rounded-md ${className}`}>
      <Select value={value} onValueChange={onValueChange} required={required}>
        <SelectTrigger className='flex gap-2 w-full justify-center'>
          <SelectValue placeholder={placeholder} className='text-muted' />
        </SelectTrigger>

        <SelectContent className='flex flex-col max-h-64 overflow-y-auto w-full items-center'>
          {options.map((groupOrItem, index) => (
            isGroupOption(groupOrItem) ? (
              <SelectGroup key={index} className='border mb-2'>
                {groupOrItem.groupLabel &&
                  <SelectLabel>{groupOrItem.groupLabel}</SelectLabel>
                }
                {groupOrItem.options.map((option, idx) => (
                  <SelectItem key={idx} value={option.value} className='pl-10'>
                    {option.label ? option.label : option.value}
                  </SelectItem>
                ))}
              </SelectGroup>
            ) : renderOption ? (
              <SelectGroup key={index}>
                <SelectItem key={index} value={groupOrItem.value}>
                  {renderOption(groupOrItem)}
                </SelectItem>
              </SelectGroup>
            ) : (
              <SelectGroup key={index}>
                <SelectItem key={index} value={groupOrItem.value}>
                  {groupOrItem.label ? groupOrItem.label : groupOrItem.value}
                </SelectItem>
              </SelectGroup>
            )
          ))}
        </SelectContent>
      </Select>

      {value !== '' && value !== undefined && !required &&
        <Button
          className='p-0'
          variant='ghost'
          size='icon'
          onClick={(e) => {
            e.stopPropagation()
            onValueChange('')
          }}
        >
          <XIcon />
        </Button>
      }
    </div>
  )
}

export default Selector