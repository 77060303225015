'use client'

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import useBlockingPopup from '@/hooks/useBlockingPopup'
import { ExtendedProject as Project } from '@/lib/project'
import { ExtendedUser as User } from '@/lib/user'
import { PictureInPicture2 } from "lucide-react"
import { useState } from 'react'
import { Button } from "./ui/button"

interface BlockingPopupProps {
  user: User
  projects: Project[]
}

export default function BlockingPopup({ user, projects }: BlockingPopupProps) {
  const popupContent = useBlockingPopup({ user, projects })
  const [isOpen, setIsOpen] = useState(true)

  return (
    isOpen ? (
      <Dialog open={!!popupContent} onOpenChange={setIsOpen}>
        <DialogContent
          hideCloseButton={!user || !user.role || user.role !== 'SUPER_ADMIN'}
          onInteractOutside={(e) => user?.role === 'SUPER_ADMIN' ? null : e.preventDefault()}
          onEscapeKeyDown={(e) => user?.role === 'SUPER_ADMIN' ? null : e.preventDefault()}
          className="max-w-3xl max-h-[80vh] overflow-y-auto"
        >
          <DialogHeader>
            <DialogTitle className="text-3xl">Action requise</DialogTitle>
            <DialogDescription>Une action est requise pour pouvoir continuer</DialogDescription>
          </DialogHeader>
          {popupContent}
        </DialogContent>
      </Dialog >
    ) : (
      <Button className="absolute bottom-5 right-5 items-center flex gap-2 z-20" variant="outline" onClick={() => setIsOpen(true)}>
        <PictureInPicture2 /> Action requise
      </Button>
    )
  )
}